import React, { useEffect } from "react";
import "./homepage.css";
import headerImg from "../../components/assets/DreamyDraw-24-Homepage-Assets/main-graphic-2x.svg";
import LineupCarousel from "../../components/lineupSlides/LineupCarousel";
import BoxCard from "../../components/boxCard/BoxCard";
import patternIcon from "../../components/assets/DreamyDraw-24-Homepage-Assets/pattern-icon-2x.svg";
import initializeFacebookPixel from "../../utils/metapixel"; // Import the metapixel.js file
import ddBorder from "../../components/assets/DreamyDraw-24-Homepage-Assets/border-2x.svg"
import NewsletterSignup from "../../components/newsletterSignup/NewsLetterSignup";
import LineupArt from "../../components/assets/DD-24-tix-lineup/Lineup/PNG/Web-Lineup-Final.png";

const Homepage = () => {
  // Use useEffect to load and initialize Meta Pixel
  useEffect(() => {
    initializeFacebookPixel();
    window.fbq("track", "HomePage");
  }, []);

  return (
    <div className="homepage-container">
      {/* <HeroHeader /> */}
      <div className="homepage-lineup">
        <img src={headerImg} alt="line up for 2024" />
      </div>
      <img src={ddBorder} alt="dd-border" className="dd-border-image" style={{width: "100%"}}/>
      <img src={LineupArt} alt="DreamyDraw 24 Lineup" style={{width: "100%"}}/>
      <NewsletterSignup />
      <div className="homepage-box-card" style={{ position: "relative" }}>
        <img
          src={patternIcon}
          alt="design"
          style={{ position: "absolute", width: "10%" }}
        />
        <img
          src={patternIcon}
          alt="design"
          style={{ position: "absolute", top: "28%", right: 0, width: "10%" }}
        />
        <img
          src={patternIcon}
          alt="design"
          style={{ position: "absolute", bottom: "40%", left: 0, width: "10%" }}
        />
        <img
          src={patternIcon}
          alt="design"
          style={{ position: "absolute", bottom: "5%", right: 0, width: "10%" }}
        />
        <BoxCard />
      </div>
      
      {/* <div>
        <h2 style={{ textAlign: "center", fontSize: "2rem" }}>Partners & Sponsors</h2>
        <LineupCarousel />
      </div> */}
    </div>
  );
};

export default Homepage;
