import React, { useState } from "react";
import { FiPlus, FiMinus } from 'react-icons/fi'


const TicketQandA = () => {
  const TicketQuestions =
    [
   
      {
        id: 3,
        question: "CAN I EXCHANGE/RETURN MY TICKET?",
        answer:
          "All sales are final. Tickets are nonrefundable no matter the circumstance. When purchasing a ticket, please carefully review your purchase before submitting your final order.",
      },
     
    ];

  const [clicked, setClicked] = useState(false)

  const toggle = index => {
    // if the clicked question is open, then close it.
    if (clicked === index) {
      return setClicked(null)
    }
    // else, open the question when its clicked on
    setClicked(index)
  }
  return (

    <div className="faqs-container" id="ticketQuestions">
      <h1>Tickets</h1>
      {TicketQuestions.map((faq, index) => {
        return (
          <div key={index}>
            <div className="faqs-question" onClick={() => toggle(index)}>
              <h1>{faq.question}</h1>
              <span>{clicked === index ? <FiMinus color="#ffffff" /> : <FiPlus color="#ffffff" />}</span>
            </div>
            {clicked === index ? (
              <div className="faqs-answer">
                <p>{faq.answer}</p>
              </div>
            ) : null}

          </div>
        )
      })}
    </div>
  );
};

export default TicketQandA;
