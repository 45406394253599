import React, { useState  } from 'react';
import "./newslettersignup.css"

const NewsletterSignup = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    window.HIVE_SDK('submitSignupForm', event.target, () => {
      onFormSubmitSuccess();
      setEmail(''); // Clear the input field after successful submission
    });
  };

  const onFormSubmitSuccess = () => {
    console.log("success");
    alert("You've been successfully subscribed - thanks!");
  };

  return (
    <div className="text-updates-main">
      <div className="text-updates-signup">
        <h5>Sign Up for Updates</h5>
      </div>
      <form className="text-updates-input" onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Your Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          data-HIVE-FORM-FIELD="email"
          autoComplete="off"
        />
        <button type="submit" data-HIVE-FORM-FIELD="submitButton">SUBMIT</button>
        <input data-HIVE-FORM-FIELD="swid" type="hidden" value="6698" autoComplete='off' />
        <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
          <input type="text" data-HIVE-FORM-FIELD="areUReal" tabIndex="-1" value="" autoComplete='off' />
        </div>
      </form>
    </div>
  );
};

export default NewsletterSignup;