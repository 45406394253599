import React, { useState } from "react";
import { FiPlus, FiMinus } from "react-icons/fi";

const WhatCantIBring = () => {
  const CantQuestions = [
    {
      id: 1,
      question:
        "YOUR SAFETY AND THE ENJOYMENT OF ALL GUESTS IS THE MOST IMPORTANT THING, WHICH IS WHY WE'VE UPDATED OUR LIST OF PROHIBITED ITEMS THIS YEAR.",
      answer: `-Totems`,
      answer1: "-Chairs of any kind",
      answer2: `-Wagons`,
      answer3: `-Drugs / drug paraphernalia`,
      answer4: `-Outside food + drink`,
      answer5: `-Laser Pointers`,
      answer6: `-Lighters`,
      answer7: `-Large bottles - One empty 24oz (or less) transparent plastic bottle (Nalgene style) or clear plastic water bottle OK)`,
      answer8: `-Large backpacks (CLEAR BAGS ONLY – SEE CLEAR BAG POLICY)`,
      answer9: `-Glass bottles or containers`,
      answer10: `-Weapons of any kind`,
      answer11: `-Selfie sticks`,
      answer12: `-Audio recording devices`,
      answer13: `-Professional photo / video cameras`,
      answer14: `-Drones`,
      answer15: `-Umbrellas (parasols ok)`,
      answer16: `-Stickers / flyers / solicitations`,
      answer17: `-Pets (guide/service dogs OK)`,
    },
  ];

  const [clicked, setClicked] = useState(false);

  const toggle = (index) => {
    // if the clicked question is open, then close it.
    if (clicked === index) {
      return setClicked(null);
    }
    // else, open the question when its clicked on
    setClicked(index);
  };
  return (
    <div className="faqs-container" id="CantQuestions">
      <h1>What Can't I Bring In?</h1>
      {CantQuestions.map((faq, index) => {
        return (
          <div key={index}>
            <div className="faqs-question" onClick={() => toggle(index)}>
              <h1>{faq.question}</h1>
              <span>{clicked === index ? <FiMinus color="#ffffff" /> : <FiPlus color="#ffffff" />}</span>
            </div>
            {clicked === index ? (
              <div className="faqs-answer">
                <p>{faq.answer}</p>
                <p>{faq.answer1}</p>
                <p>{faq.answer2}</p>
                <p>{faq.answer3}</p>
                <p>{faq.answer4}</p>
                <p>{faq.answer5}</p>
                <p>{faq.answer6}</p>
                <p>{faq.answer7}</p>
                <p>{faq.answer8}</p>
                <p>{faq.answer9}</p>
                <p>{faq.answer10}</p>
                <p>{faq.answer11}</p>
                <p>{faq.answer12}</p>
                <p>{faq.answer13}</p>
                <p>{faq.answer14}</p>
                <p>{faq.answer15}</p>
                <p>{faq.answer16}</p>
                <p>{faq.answer17}</p>
                <p>{faq.answer18}</p>
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default WhatCantIBring;
