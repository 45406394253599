import React, { useEffect } from "react";
import "./eachlineup.css";
import { useParams, useNavigate, useLocation } from "react-router-dom"; // USE USEHISTORY -> USENAVIGATE
import Data from "../../components/cards/lineupcards/data";
import initializeFacebookPixel from "../../utils/metapixel"; // Import the metapixel.js file

const EachLineup = () => {
  const { name } = useParams();
  const navigate = useNavigate(); // USE USEHISTORY -> USENAVIGATE
  const location = useLocation(); // ADD USELOCATION HOOK

  useEffect(() => {
    let names = name;
    initializeFacebookPixel();
    window.fbq("track", `${names}`);
  }, [name]); // ADD NAME AS A DEPENDENCY

  const handleBackClick = () => { // ADD HANDLEBACKCLICK FUNCTION
    const activeClass = new URLSearchParams(location.search).get("activeClass") || "FirstLineup"; // RETRIEVE ACTIVECLASS FROM URL
    navigate(`/lineup?activeClass=${activeClass}`); // NAVIGATE TO LINEUP PAGE WITH ACTIVECLASS
  };

  return (
    <div className="each-lineup-container">
      {Data.filter((lineup) => lineup.id === name).map((lineup, index) => (
        <div className="each-single-page-container" key={index}>
          <div className="each-page-left">
            <div className="each-page-mockup">
              <img
                src={lineup.img}
                alt={lineup.name}
                className="each-page-img"
              />
            </div>
            <div className="each-page-right-social">
              <a href={lineup.fblink} target="_blank" rel="noreferrer">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href={lineup.instalink} target="_blank" rel="noreferrer">
                <i className="fab fa-instagram"></i>
              </a>
              <a href={lineup.spotifylink} target="_blank" rel="noreferrer">
                <i className="fab fa-spotify"></i>
              </a>
            </div>
          </div>
          <div className="each-page-right">
            <h1>{lineup.name}</h1>
            <iframe
              title="my-spotify"
              className="spotify-player"
              src={lineup.spotifysource}
              width="90%"
              height="450"
              frameBorder="0"
              allowFullScreen=""
              allow="clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
            ></iframe>
            {lineup.desc.map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default EachLineup;
