import React from "react";
import "./footer.css";
import { BsInstagram, BsFacebook } from "react-icons/bs";
import headerimg from "../assets/DreamyDraw-24-Homepage-Assets/logo-2x-footer.svg";
import ddBorder from "../../components/assets/DreamyDraw-24-Homepage-Assets/border-2x.svg"

const Footer = () => {
  return (
    <>
    <footer className="footer-container">
      <div className="footer-img">
        <img src={headerimg} alt="m3f logo" style={{height: "80px"}}/>
      </div>
      <div className="footer-icons footer-icons-seprator">
        <div className="footer-separator-line"></div>
        <a
          href="https://www.instagram.com/dreamydrawfest/"
          rel="noreferrer"
          target="_blank"
          >
          <BsInstagram />
        </a>
        <a
          href="https://www.facebook.com/people/Dreamy-Draw-Music-Festival/100094781405530/"
          rel="noreferrer"
          target="_blank"
          >
          <BsFacebook />
        </a>
        <div className="footer-separator-line"></div>
      </div>
      <div className="footer-links">
        <a href="mailto:info@dreamydrawfest.com">CONTACT US</a>
        <a href="mailto:parker@ohwowcompany.com">MEDIA INQUIRIES</a>
        <a href="mailto:clarissa@ohwowcompany.com">SPONSORSHIP INQUIRIES</a>
      </div>
      <div className="footer-copyright">
        <h5>&copy; 2024 DREAMY DRAW MUSIC FESTIVAL LLC</h5>
      </div>
    </footer>
    <div className="dd-border-img-div">
      <img src={ddBorder} alt="dd-border" className="dd-border-image" style={{width: "100%"}}/>
    </div>
          </>
  );
};

export default Footer;
