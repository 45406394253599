import React, { useState } from "react";
import { FiPlus, FiMinus } from 'react-icons/fi'

const GettingHere = () => {
  const getHere = [
    {
      id: 1,
      question: "WHERE IS DREAMY DRAW?",
      answer:
        "Dreamy Draw is located at the Scottsdale Civic Center 7380 E 2nd St, Scottsdale, AZ 85251",
    },
    {
      id: 1,
      question: "IS THERE PARKING AVAILABLE ON SITE?",
      answer:
        "There is no parking associated with Dreamy Draw. However, there are several parking garages near the venue in Old Town Scottsdale.",
    },
    // {
    //   id: 1,
    //   question: "CAN I BIKE TO THE FESTIVAL?",
    //   answer:
    //     "Bike valet services are available outside of Festival grounds near our main entrance at 7380 E 2nd St, Scottsdale. Bikes will not be permitted within the grounds.",
    // },
  ];

  const [clicked, setClicked] = useState(false)

  const toggle = index => {
    // if the clicked question is open, then close it.
    if (clicked === index) {
      return setClicked(null)
    }
    // else, open the question when its clicked on
    setClicked(index)
  }
  return (

    <div className="faqs-container" id="gettingHereQuestions">
      <h1>Getting Here</h1>
      {getHere.map((faq, index) => {
        return (
          <div key={index}>
            <div className="faqs-question" onClick={() => toggle(index)}>
              <h1>{faq.question}</h1>
              <span>{clicked === index ? <FiMinus color="#ffffff" /> : <FiPlus color="#ffffff" />}</span>
            </div>
            {clicked === index ? (
              <div className="faqs-answer">
                <p>{faq.answer}</p>
              </div>
            ) : null}

          </div>
        )
      })}
    </div>
  );
};

export default GettingHere;
