import React, { useEffect } from "react";
import TicketsWeb from "../../components/assets/DD-24-tix-lineup/Tickets/SVG/tickets-2x.svg";
import "./ticket.css";
import TicketBoxCard from "./TicketBoxCard";
import initializeFacebookPixel from "../../utils/metapixel"; // Import the metapixel.js file
import ddBorder from "../../components/assets/DreamyDraw-24-Homepage-Assets/border-2x.svg"

const Tickets = () => {
  
    // Use useEffect to load and initialize Meta Pixel
    useEffect(() => {
      initializeFacebookPixel();
      window.fbq("track", "HomePage");
    }, []);

  return (
    <>
      <div className="tickets-header-container">
        <img
          className="tickets-header-img"
          src={TicketsWeb}
          alt="tickets header background"
        />
      <img src={ddBorder} alt="dd-border" className="dd-border-image" style={{width: "100%"}}/>
      </div>
      <TicketBoxCard />
    </>
  );
};

export default Tickets;
