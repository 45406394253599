import React from "react";
import "./boxcard.css";
import Food from "../../components/assets/DD-24-tix-lineup/images/FOOD.png";
import Market from "../../components/assets/DD-24-tix-lineup/images/activations.png";
import Art from "../../components/assets/DD-24-tix-lineup/images/Trading Post.png";
import DynamicDiamonds from "../DynamicDiamonds/DynamicDiamonds";

const data = [
  //   id: 1,
  //   name: "The PTS Farms Green Room",
  //   desc: "With your PTS Farms Green Room Pass, you will have access to the only sesh spot at M3F. Each pass will grant you an invite to come smoke with us in a comfortable furnished environment at the Festival.Ticket includes daily sample bags including products from Paul Bunyan Cannabis and Tonic Cannabis Beverages.",
  //   lists: [
  //     "PTS Green Room Pass Perks",
  //     "Pre Rolls and Flower by Paul Bunyan",
  //     "Cannabis Cocktails brought to you by Tonic Cannabis Beverages",
  //     "Cannabis Freeze Pops by Tonic Frost",
  //     "Secret Snacks and possibly pencil cases!",
  //   ],
  //   img: Drinks,
  //   classNameName: "experience-beginnings-content"
  // },
  {
    id: 2,
    name: "Art & Experience",
    desc: "Immerse yourself in a vibrant world of creativity as you explore Dreamy Draw's festival grounds, set in the stunning Scottsdale Civic Center. Experience the unique charm of a Western-inspired atmosphere, with captivating immersive art installations and picturesque surroundings.",
    img: Market,
    blogpostclass: "blog-post blog-post-row",
    blogpostimgclass: "blog-post-img",
  },
  {
    id: 3,
    name: "The Dreamy Trading Post",
    desc: "Explore a diverse array of local artisans and vendors at Dreamy Draw. From intricately handcrafted jewelry to captivating paintings and one-of-a-kind fashion pieces, you'll find a treasure trove of creativity. Engage with talented vendors, learn about their artistic process, and support their passion for craftsmanship. Each vendor brings their unique perspective and expertise, adding to the vibrant tapestry of the festival. Discover hidden gems and take home something extraordinary from the festival, a tangible reminder of the creativity that flourishes at Dreamy Draw.",
    img: Art,
    blogpostclass: "blog-post",
    blogpostimgclass: "blog-post-img blog-post-img-rotate",
  },
  {
    id: 4,
    name: "Food & Drinks",
    desc: "Whether you're a foodie seeking new gastronomic adventures or simply looking to refuel between performances, Dreamy Draw's food and drink offerings will satisfy your cravings and enhance your festival experience. Enjoy everything from mouth watering BBQ and savory tacos to gourmet burgers and vegan delights. Quench your thirst with craft beers, specialty cocktails, and artisanal non-alcoholic options.",
    img: Food,
    blogpostclass: "blog-post blog-post-row",
    blogpostimgclass: "blog-post-img",
  },
  // {
  //   id: 5,
  //   name: "Drinks",
  //   desc: "Quench your thirst with a wide selection of refreshing beverages, including craft beers, specialty cocktails, and artisanal non-alcoholic options. Whether you're a foodie seeking new gastronomic adventures or simply looking to refuel between performances, Dreamy Draw's food and drink offerings will satisfy your cravings and enhance your festival experience.",
  //   img: Drinks,
  //   blogpostclass: "blog-post",
  //   blogpostimgclass: "blog-post-img blog-post-img-rotate",
  // },
];

const BoxCard = () => {
  return (
    <>
      {data.map((item) => (
        <div className="blog-container" key={item.id}>
          <div className={item.blogpostclass}>
            <div className="blog-post__img">
              <img src={item.img} alt="food" className={item.blogpostimgclass}/>
            </div>
            <div className="blog-post__info">
              {/* <h1 className="blog-post__title">{item.name}</h1> */}
              <DynamicDiamonds title={item.name}/>
              <p className="blog-post__text">{item.desc}</p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default BoxCard;
