import React, { useState } from "react";
import { FiPlus, FiMinus } from 'react-icons/fi'

const GeneralInfo = () => {
  const GeneralQuestions = [
    {
      id: 1,
      question: "WHERE IS THE ENTRANCE TO DREAMY DRAW?",
      answer: "The entrance is located at 7380 E 2nd St, Scottsdale, AZ",
    },
    {
      id: 2,
      question: "WHEN DO GATES OPEN?",
      answer: "Friday November 1st: 3:00pm - Saturday November 2nd: 1:00pm",
    },
    {
      id: 3,
      question: "WHAT IS THE BAG POLICY?",
      answer: "All persons and bags are subject to search. All bags, totes, backpacks must be clear plastic, vinyl or PVC and be no larger than 12\" x \"12\" x 8\". Fanny packs do NOT have to be clear, but must not exceed 5\" x 7\". Wallets / small clutches do NOT have to be clear, but must not exceed 5\" x 7\". Purses and clutches do NOT have to be clear, but must not exceed 5\" x 7\".",
    },
    {
      id: 4,
      question:
        "WILL CHILDREN UNDER A CERTAIN AGE BE ABLE TO ATTEND FOR FREE?",
      answer: "Children under the age of 12 can enter the Festival for free.",
    },
    {
      id: 5,
      question: "WHAT IS A CASHLESS EVENT?",
      answer:
        "Paper money or coins will not be able to be used at the event. Vendors will accept credit and debit cards.",
    },
    {
      id: 6,
      question: "LOST AND FOUND?",
      answer:
        "Please visit the information booth located on the festival grounds. Notice something missing after the Festival ends? Please email info@dreamydrawfest.com. They will be sure to get back to you within 3-5 days after the last day of the Festival on where you can pick up your missing goods.",
    },
  ];

  const [clicked, setClicked] = useState(false)

  const toggle = index => {
    // if the clicked question is open, then close it.
    if (clicked === index) {
      return setClicked(null)
    }
    // else, open the question when its clicked on
    setClicked(index)
  }
  return (

    <div className="faqs-container" id="generalQuestions">
      <h1>General Questions</h1>
      {GeneralQuestions.map((faq, index) => {
        return (
          <div key={index}>
            <div className="faqs-question" onClick={() => toggle(index)}>
              <h1>{faq.question}</h1>
              <span>{clicked === index ? <FiMinus color="#ffffff" /> : <FiPlus color="#ffffff" />}</span>
            </div>
            {clicked === index ? (
              <div className="faqs-answer">
                <p>{faq.answer}</p>
              </div>
            ) : null}

          </div>
        )
      })}
    </div>

  );
};

export default GeneralInfo;
