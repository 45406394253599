import React from "react";
import Navbar from "./components/navbar/Navbar";
import "./App.css";
import Homepage from "./pages/homepage/Homepage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Tickets from "./pages/ticket/Tickets";
import Info from "./pages/info/Info";
// import ComingSoon from "./pages/comingsoon/ComingSoon";
import Footer from "./components/footer/Footer";
import Lineup from "./pages/lineup/Lineup";
import EachLineup from "./pages/eachLineup/EachLineup";
import Partners from "./pages/partners/Partners";
import ScrollToTop from "./ScrollToTop";
import Faqs from "./components/faqs/Faqs";

const App = () => {
  return (
    <Router>
      <Navbar />
      <ScrollToTop>
        <Routes>
          <Route path="/*" element={<Homepage />} /> 
          <Route path="/tickets" element={<Tickets />} />
          <Route path="/lineup" element={<Lineup />} />
          <Route path="/lineup/:name" element={<EachLineup />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/faqs" element={<Faqs />} />
          {/* <Route path="/shop" element={<ComingSoon />} /> */} */
        </Routes>
      </ScrollToTop>
      <Footer />
    </Router>
  );
};

export default App;
