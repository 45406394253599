import React, { useState } from "react";
import { FiPlus, FiMinus } from 'react-icons/fi'


const GetInvolved = () => {
  const GetQuestions = [
    {
      id: 1,
      question: "WHERE CAN I SIGN UP TO VOLUNTEER?",
      answer1: "Volunteer Application",
      answer2: "Street Team Application"
    },
  ];


  const [clicked, setClicked] = useState(false)

  const toggle = index => {
    // if the clicked question is open, then close it.
    if (clicked === index) {
      return setClicked(null)
    }
    // else, open the question when its clicked on
    setClicked(index)
  }
  return (

    <div className="faqs-container" id="getQuestions">
      <h1>Get Involved</h1>
      {GetQuestions.map((faq, index) => {
        return (
          <div key={index}>
            <div className="faqs-question" onClick={() => toggle(index)}>
              <h1>{faq.question}</h1>
              <span>{clicked === index ? <FiMinus color="#ffffff" /> : <FiPlus color="#ffffff" />}</span>
            </div>
            {clicked === index ? (
              <div className="faqs-answer">
                <a href="https://docs.google.com/forms/d/e/1FAIpQLScHaZ87aE-JawD_NQZiemb-igr-1xissjmIUQR2mu-II51frQ/viewform">{faq.answer1}</a>
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSe0hZ8qAnyYxFdGkMuieEz2ei2DhYy3Be56ubAiQMsds3Yg-Q/viewform">{faq.answer2}</a>
              </div>
              
            ) : null}

          </div>
        )
      })}
    </div>
  );
};

export default GetInvolved;