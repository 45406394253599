import React, { useEffect, useState } from "react";
import "./Navbar.css";
import { MdClose } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import headerimg from "../assets/DreamyDraw-24-Homepage-Assets/logo-2x-navbar.svg";
import hamburgermenu from "../assets/DD-Website-Assets/hamburger-menu.png";
import ticketBtn from "../assets/DD-24-tix-lineup/Lineup/PNG/Tickets-img-btn.png";
import ddborder from "../assets/DreamyDraw-24-Homepage-Assets/border-2x.svg";

// framer motion
import { AnimatePresence, motion } from "framer-motion";

const links = [
  { name: "HOMEPAGE", to: "/", id: 1 },
  { name: "LINEUP", to: "/lineup", id: 2 },
  { name: "TICKETS", to: "/tickets", id: 3 },
  { name: "THINGS TO KNOW", to: "/faqs", id: 4 },
];
 
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  // show image in the navbar on all other pages but the homepage
  const [showHeaderImg, setShowHeaderImg] = useState(true);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      setShowHeaderImg(true); // if wanna hide the header img set this to false
    } else {
      setShowHeaderImg(true);
    }
  }, [location]);

  return (
    <>
    <div className="dd-border-img-div">
      <img src={ddborder} alt="dd-border" className="dd-border-image" style={{width: "102%"}}/>
    </div>
      <div className="main-container">
        <div
          className="navbar-header-img"
          style={showHeaderImg ? { margin: "1rem" } : { margin: "3rem" }}
        >
          {showHeaderImg && <img src={headerimg} alt="header" />}
        </div>
        <div className="navbar-content">
          <nav className="navbar">
            <AnimatePresence>
              {isOpen && (
                <motion.div
                  key={"mobile-menu"}
                  variants={{
                    open: {
                      x: "0%",
                      transition: {
                        when: "beforeChildren",
                        type: "spring",
                        bounce: 0.099,
                        duration: 0.25,
                      },
                    },
                    close: {
                      x: "-100%",
                      transition: {
                        when: "afterChildren",
                        type: "spring",
                        bounce: 0.099,
                        duration: 0.25,
                      },
                    },
                  }}
                  initial={"close"}
                  animate={"open"}
                  exit={"close"}
                  className={`menu ${isOpen ? "open" : ""}`}
                >
                  <motion.ul
                    variants={{
                      open: {
                        y: "0%",
                        opacity: 1,
                      },
                      close: {
                        y: "45%",
                        opacity: 0,
                      },
                    }}
                  >
                    {links.map(({ name, to, id }) => (
                      <li key={id}>
                        <Link to={to} onClick={closeMenu}>
                          {name}
                        </Link>
                      </li>
                    ))}
                  </motion.ul>
                  <div className="close-icon" onClick={toggleMenu}>
                    <MdClose size={"2rem"} color={"white"} />
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
            <div
              className={`menu-icon ${isOpen ? "open" : ""}`}
              onClick={toggleMenu}
            >
              <img src={hamburgermenu} alt="hamburger menu" />
            </div>
          </nav>
          <Link to="/tickets" className="navbar-btn">
            <img src={ticketBtn} alt="tickets button" />
          </Link>
        </div>
      </div>
    </>
  );
};

export default Navbar;
