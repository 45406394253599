// metapixel.js

// Replace 'YOUR_FACEBOOK_PIXEL_ID' with your actual Facebook Pixel ID
const FB_PIXEL_ID = 3594064697474874;

// Function to initialize Facebook Pixel
function initializeFacebookPixel() {
  // Don't initialize Facebook Pixel if it's already initialized
  if (window.fbq) return;

  // Initialize Facebook Pixel
  window.fbq = function () {
    window.fbq.callMethod
      ? window.fbq.callMethod.apply(window.fbq, arguments)
      : window.fbq.queue.push(arguments);
  };
  if (!window._fbq) window._fbq = window.fbq;
  window.fbq.push = window.fbq;
  window.fbq.loaded = true;
  window.fbq.version = "2.0";
  window.fbq.queue = [];

  // Load Facebook Pixel
  const script = document.createElement("script");
  script.async = true;
  script.src = "https://connect.facebook.net/en_US/fbevents.js";
  document.head.appendChild(script);

  // Initialize Facebook Pixel with your Pixel ID
  window.fbq("init", FB_PIXEL_ID);
}

// Initialize Facebook Pixel when the page is ready
initializeFacebookPixel();

export default initializeFacebookPixel; // Export the function to be used in other files
