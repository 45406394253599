import React from "react";
import "./partners.css";
import PartnerHeader from "../../images/sixth.mov";

const Partnersheader = () => {
  return (
    <div>
      <div className="partners-backimg">
        <video
          src={PartnerHeader}
          typeof="video/mov"
          autoPlay
          loop
          muted
          playsInline
        />
      </div>
    </div>
  );
};

export default Partnersheader;
