
import adobeDrywall from '../../images/adobeDrywall.png'

const partnerslogo = [{
  id: 1,
  name: adobeDrywall,

},

]

export default partnerslogo;