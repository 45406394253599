import React, { useEffect, useState, useRef } from 'react';
import './DynamicDiamonds.css'; // Ensure to create this CSS file

const DynamicDiamonds = ({ title }) => {
  const [numberOfDiamonds, setNumberOfDiamonds] = useState(0);
  const titleRef = useRef(null);

  useEffect(() => {
    const createDiamonds = () => {
      const diamondWidth = 20; // width of one diamond in px
      const gap = 32; // gap between diamonds in px
      const containerWidth = titleRef.current ? titleRef.current.clientWidth : 0;
      const minDiamonds = 6; // minimum number of diamonds
      const calculatedDiamonds = Math.floor(containerWidth / (diamondWidth + gap));
      const numDiamonds = Math.max(calculatedDiamonds, minDiamonds);
      setNumberOfDiamonds(numDiamonds);
    };

    // Initial calculation
    createDiamonds();

    // Recalculate on window resize
    window.addEventListener('resize', createDiamonds);
    return () => window.removeEventListener('resize', createDiamonds);
  }, []);

  return (
    <div className="title-container">
      <h2 className="diamond-h2" ref={titleRef}>{title}</h2>
      <div className="diamond-container">
        {Array.from({ length: numberOfDiamonds }, (_, index) => (
          <div key={index} className="diamond"></div>
        ))}
      </div>
    </div>
  );
};

export default DynamicDiamonds;
