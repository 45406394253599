import React, { useState } from "react";
import { FiPlus, FiMinus } from 'react-icons/fi'


const WhatCanIBring = () => {
  const CanQuestions = [
    {
      id: 1,
      question: "BESIDES GOOD VIBES AND A SMILE WE ALLOW ALL OF THE BELOW ITEMS AT DREAMY DRAW:",
      answer: `-Clear backpacks, totes, handbags, plastic that are vinyl or PVC and do not exceed 12" x 12" x 8"`,
      answer1: `-Empty single-pocket hydration packs are allowed. They must be emptied of all liquid and have no more than one pockets in addition to the one holding the water reservoir. They must be no larger than 12”x12”x8”`,
      answer2: `-Small clutch bags, fanny packs & wallets that do not exceed 5" x 7"`,
      answer3: `-Cell phones`,
      answer4: `-Non-professional cameras`,
      answer6: `-Hand sanitizer and/or wipes (open/unsealed ok)`,
      answer7: `-Sunscreen`,
      answer8: `-Bug spray`,
      answer9: `-Chapstick / lipstick / makeup / eyedrops / gum (open/unsealed ok)`,
      answer10: `-Empty 24oz (or less) transparent plastic bottle (Nalgene style) or clear plastic water bottle`,
      answer11: `-Prescribed medication (in container with matching ID to be checked at entry)`,
      answer12: `-GoPros (w/o sticks)`,
      answer13: `-Sunglasses`,
      answer14: `-Earplugs`,
      answer15: `-Hats`,
    },
  ];


  const [clicked, setClicked] = useState(false)

  const toggle = index => {
    // if the clicked question is open, then close it.
    if (clicked === index) {
      return setClicked(null)
    }
    // else, open the question when its clicked on
    setClicked(index)
  }
  return (

    <div className="faqs-container" id="CanQuestions">
      <h1>What Can I Bring?</h1>
      {CanQuestions.map((faq, index) => {
        return (
          <div key={index}>
            <div className="faqs-question" onClick={() => toggle(index)}>
              <h1>{faq.question}</h1>
              <span>{clicked === index ? <FiMinus color="#ffffff" /> : <FiPlus color="#ffffff" />}</span>
            </div>
            {clicked === index ? (
              <div className="faqs-answer">
                <p>{faq.answer}</p>
                <p>{faq.answer1}</p>
                <p>{faq.answer2}</p>
                <p>{faq.answer3}</p>
                <p>{faq.answer4}</p>
                <p>{faq.answer5}</p>
                <p>{faq.answer6}</p>
                <p>{faq.answer7}</p>
                <p>{faq.answer8}</p>
                <p>{faq.answer9}</p>
                <p>{faq.answer10}</p>
                <p>{faq.answer11}</p>
                <p>{faq.answer12}</p>
                <p>{faq.answer13}</p>
                <p>{faq.answer14}</p>
                <p>{faq.answer15}</p>
              </div>
               
            ) : null}

          </div>
        )
      })}
    </div>
  );
};

export default WhatCanIBring;