import React from 'react';
import './partners.css'
import partnerslogo from './partnerslogo';

const PartnersLogo = () => {

    return (

        <div className="partners-slider-area">
            <h2>Our Partners</h2>
            <div className="partners-wrapper">
                {
                    partnerslogo.map((item) => (
                        <div key={item.id} className="partners-item"> <img alt="partners logos" src={item.name} /></div>
                    )
                    )
                }
            </div>
        </div>

    )
};

export default PartnersLogo;