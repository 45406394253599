import React from "react";
import "./Linupcard.css";
import LineUpData from "./FriData";
import { Link } from "react-router-dom";

const FridayLineupCard = () => {
  return (
    <>
      {LineUpData.map((lineup, index) => {
        return (
          <div className="jane-doe-container" key={index}>
            <div className="jane-doe-card-wrapper">
              <div className="jane-doe-card jane-doe-profile-two">
                <Link to={`/lineup/${lineup.id}`}>
                  {" "}
                  <div className="jane-doe-card-image jane-doe-profile-img--two">
                    <img src={lineup.img} alt="profile two" />
                  </div>
                </Link>
                <ul className="jane-doe-social-icons">
                  <li>
                    <a href={lineup.fblink} target="_blank" rel="noreferrer">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href={lineup.instalink} target="_blank" rel="noreferrer">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href={lineup.spotifylink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-spotify"></i>
                    </a>
                  </li>
                </ul>

                <div className="jane-doe-details jane-doe-jane">
                  <div className="lineup-card-h2-border">
                    <div className="box">
                      <div className="box__line box__line--top"></div>
                      <div className="box__line box__line--right"></div>
                      <div className="box__line box__line--bottom"></div>
                      <div className="box__line box__line--left"></div>
                      <h2>{lineup.name}</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default FridayLineupCard;
