import IzzyMahoubi from "../../../images/IzzyMahoubi.jpeg";
import CardinalBloom from "../../../images/cardinalBloom.jpeg";
import Josiah from "../../../images/Josiah.jpg"
import Julia from "../../../images/julia.jpeg"
import Rayland from "../../../images/Rayland.jpeg"
import Shakey from "../../../images/shakey.jpg"
import Shane from "../../../images/Shane.jpg"
import Joy from "../../../images/Joy.jpg"

const Data = [
  {
    id: "cardinalbloom",
    name: "Cardinal Bloom",
    fblink: "https://www.instagram.com/cardinalbloomofficial/",
    instalink: "https://www.instagram.com/cardinalbloomofficial/",
    spotifylink: "https://open.spotify.com/artist/1kL35cnfsVSfKJ6xLk0K9f?si=naOdCqwcRrm2nStnRS61qA&nd=1&dlsi=293eef177d2f41de",
    spotifysource:
      "https://open.spotify.com/embed/artist/1kL35cnfsVSfKJ6xLk0K9f?utm_source=generator",
    img: CardinalBloom,
    desc: [
      `Cardinal Bloom is an Indie Rock Band from Salt Lake City Utah, one of the birth places of the modern Indie Rock genre. Cardinal Bloom has recently grown a significant fanbase after releasing their singles “She’s Just a Friend”, “Nothing Stays the Same”, and having announced a new single “12:30” for release in July. Cardinal Bloom’s music encapsulates the nostalgic and bittersweet feeling of ‘better days,’ yet leaving you a notion there is still so much life ahead of us to be lived.`,
    ],
  },
  {
    id: "izzymahoubi",
    name: "Izzy Mahoubi",
    instalink: "https://www.instagram.com/izzymahoubi/",
    spotifylink: "https://open.spotify.com/artist/1UvV134cJBqKxKFwLR2bPa",
    spotifysource:
      "https://open.spotify.com/embed/artist/1UvV134cJBqKxKFwLR2bPa?utm_source=generator",
    img: IzzyMahoubi,
    desc: [`For as long as she can remember, 18 year old Izzy Mahoubi has been capturing the world around her with songwriting and poetry. Hailing from Phoenix, Arizona, Izzy’s relatable storytelling and refreshing melodies have a unique authenticity that is undeniable. Her genre has been described as singer-songwriter with definite elements of indie pop and folk. Inspired by artists such as Maggie Rogers, Taylor Swift, and Phoebe Bridgers, Izzy has also taken cues from musicians such as Joni Mitchell, Tom Petty, Fleetwood Mac, and The Beatles.`,
    `Izzy has recently signed with indie record label, RUDE Records and has released three singles with them. Her latest single, ‘Torn in Two’, released on Valentine’s Day, is a jazzy melody that reflects the likes of Norah Jones and John Mayer. It has received accolades from music journals such as Rolling Stone France and listeners alike. The nostalgic yet hopeful lyrics tell the story of acceptance and growth that comes with getting older. The Arizona Republic describes her music as, “radio friendly with infectious melodies” (Ed Masley). This is only the beginning for songwriter Izzy Mahoubi as she continues to perform and open for artists such as Adam Melchor and Bendigo Fletcher and anticipates the release of her first E.P. in 2023.`,
    ],
  },
  {
    id: "josiahandthebonnevvilles",
    name: "Josian and the Bonnevilles",
    fblink: "https://www.facebook.com/josiahandthe",
    instalink: "https://www.instagram.com/josiahandthe",
    spotifylink: "https://open.spotify.com/artist/3FMcVBx2TMq2f5gEPcUieC",
    spotifysource:
      "https://open.spotify.com/embed/artist/3FMcVBx2TMq2f5gEPcUieC?utm_source=generator",
    img: Josiah,
    desc: [
      `Josiah and the Bonnevilles is a musical project led by singer-songwriter Josiah Leming. Josiah was born in Morristown, Tennessee and as a child, Josiah was fascinated by music and began playing the piano and guitar at a young age.`,
    `Josiah's musical talents became evident to his family and friends, and he began performing as a teenager catching the attention of music industry professionals. In 2010, he formed Josiah and the Bonnevilles, which presented a unique blend of Folk, Americana, and Country that draws from his roots as a true Appalachian artist, embracing honesty and putting life's realities into his songwriting.`,
    `In 2015, Josiah and the Bonnevilles released its debut album, On Trial. The album was praised for its raw, vulnerable lyrics and its combination of acoustic and electric instruments that underpinned the storytelling. The band went on tour in support of the album with the artist LP, performing at various venues across the United States and Europe.`,
    `Since the release of On Trial, Josiah and the Bonnevilles have continued to make music and tour, gaining a devoted fan base along the way. Josiah remains grounded and focused on his music. He has spoken openly about his struggles with mental health, addiction, and the music industry as a whole and uses his songwriting as a form of therapy. He continues to inspire his fans with his honesty and vulnerability, and his music has touched the hearts of people around the world.`,
    ],
  },
  {
    id: "juliadigrazia",
    name: "Julia DiGrazia",
    fblink: "https://www.facebook.com/juliadigrazmusic/",
    instalink: "https://www.instagram.com/juliadigrazmusic/",
    spotifylink: "https://open.spotify.com/artist/3bz9DtEGIYBic48d2q5m4R",
    spotifysource:
      "https://open.spotify.com/embed/artist/3bz9DtEGIYBic48d2q5m4R?utm_source=generator",
    img: Julia,
    desc: [
      `Julia DiGrazia's musical roots began in the Sonoran desert city of Phoenix, Arizona where she started with a classical background on violin and later developed a passion for fiddling. In late December of 2022, Julia started her new chapter in Nashville, Tennessee where she truly began her artistic songwriting journey. Having graduated from Berklee College of Music with a degree in Interdisciplinary Music, Julia's musical prowess is not limited to just the fiddle. When she’s not fiddling you can find her on mandolin, guitar, banjo--not to mention with a powerful singing voice. Julia’s current projects include touring alongside Evan Honer with plans to release her first album and continue adding to her musical catalogue. When Julia isn’t on the road or in the studio you can find her fiddling downtown at local Nashville bars.`,
    ],
  },
  {
    id: "mtjoy",
    name: "Mt. Joy",
    fblink: "http://facebook.com/mtjoyband",
    instalink: "http://instagram.com/mtjoyband",
    spotifylink: " https://open.spotify.com/artist/69tiO1fG8VWduDl3ji2qhI",
    spotifysource:
      "https://open.spotify.com/embed/artist/69tiO1fG8VWduDl3ji2qhI?utm_source=generator",
    img: Joy,
    desc: [
       `The Philadelphia and Los Angeles based band—Matt Quinn [vocals, guitar], Sam Cooper [guitar], Sotiris Eliopoulos [drums], Jackie Miclau [keys, piano], and Michael Byrnes [bass]— take their name from Mt. Joy in Valley Forge National Park, which is an area right outside of where they are from in Philadelphia. Forming in 2016, they made their mark two years later when their self-titled Mt. Joy (2018) debut album spun off platinum single "Silver Lining," an uplifting power ballad that went to #1 at AAA radio (Billboard Adult Alternative Airplay) and has eclipsed over 240 million streams across platforms. Their second album, Rearrange Us (2020) drew widespread critical raves, with title track gaining 92 million Spotify streams since the release. Their third LP, Orange Blood (2022), came out of the pandemic and further cemented them with another #1, Lemon Tree, at AAA and a 70 date headline tour that sold over 180K tickets. `,
      `Over the past five years, Mt. Joy has amassed over half a billion streams and earned acclaim from NPR, Billboard, Rolling Stone, The Line of Best Fit, and more. Throughout their career, the band has performed at a variety of festivals such as Lollapalooza, Austin City Limits, Bonnaroo. Additionally, the band has performed on Stephen Colbert, CBS Morning, Samantha Bee, Seth Meyers, Jimmy Kimmel, and Conan.`,
      `Upcoming plans for 2024 include new music, live show, and world tour including shows at Madison Square Garden in New York City, The Hollywood Bowl in Los Angeles, Roundhouse in London, and more.`,
      ],
  },
  {
    id: "raylandbaxter",
    name: "Rayland Baxter",
    fblink: "https://www.facebook.com/RaylandBaxterMusic",
    instalink: "https://www.instagram.com/raylandishere/",
    spotifylink: "https://open.spotify.com/artist/251UrhgNbMr15NLzQ2KyKq",
    spotifysource:
      "https://open.spotify.com/embed/artist/251UrhgNbMr15NLzQ2KyKq?utm_source=generator",
    img: Rayland,
    desc: [
      `For the making of his fourth album If I Were a Butterfly, Rayland Baxter holed up for over a year at a former rubber-band factory turned studio in the Kentucky countryside—a seemingly humble environment that proved to be something of a wonderland. The follow-up to 2018’s critically acclaimed Wide Awake, If I Were a Butterfly finds Baxter co-producing alongside Tim O’Sullivan (Grace Potter, The Head and the Heart) and Kai Welch (Molly Tuttle, Sierra Hull), slowly piecing together the album’s patchwork of lush psychedelia & Beatlesesque pop. In addition to working at Thunder Sound Baxter recorded in California, Texas, Tennessee, & Washington, enlisting a remarkable lineup of musicians: Shakey Graves, Lennon Stella, several members of Cage the Elephant, Zac Cockrell of Alabama Shakes, Morning Teleportation’s Travis Goodwin, and legendary Motown drummer Bobbye Jean Hall, among many others. In an especially meaningful turn, two of the album’s tracks feature the elegant pedal steel work of his father, Bucky Baxter (a musician who performed with Bob Dylan and who passed away in May 2020). Thanks to the extraordinary care and ingenuity behind its creation, If I Were a Butterfly arrives as a work of rarefied magic, capable of stirring up immense feeling while leaving the listener happily wonderstruck. Baxter’s debut release as a producer, If I Were a Butterfly bears a dazzling unpredictability that has much to do with his limitless imagination as a collector and collagist of sound.`,
    ],
  },
  {
    id: "shakeygraves",
    name: "Shakey Graves",
    fblink: "https://www.facebook.com/shakeygravesmusic/",
    instalink: "https://www.instagram.com/shakeygraves/",
    spotifylink: "https://open.spotify.com/artist/1fZpYWNWdL5Z3wrDtISFUH?autoplay=true&v=A",
    spotifysource:
      "https://open.spotify.com/embed/artist/1fZpYWNWdL5Z3wrDtISFUH?utm_source=generator",
    img: Shakey,
    desc: [
       `Across his career, Shakey Graves — a.k.a. the performance moniker of Austin, Texas-born Alejandro Rose-Garcia — has intentionally created thrilling musical adventures tailored to each fan: burning CDs and putting them in personalized decorated bags; building intricate scavenger hunts that send fans in search of unique tapes; and Bandcamp-exclusive releases.`,
      `"The fans and musicians that really resonate with me — and the inexplicable ways that I find things that I like — are usually entirely through randomness and chaos and accident," Rose-Garcia says. "I've always been on this quest to make people feel like my own music is a choose your own adventure."`,
      `As Rose-Garcia releases his new Shakey Graves album Movie of the Week — a collection of songs whittled down from epic-length recording sessions — he has devised one of his most innovative musical adventures yet. "For the album release, I'm setting up a website," he says. "On this website, there will be a way to shuffle a collection of alternate tracks and unique songs from the sessions in seemingly infinite combinations to create new albums." Thanks to this cutting-edge technology, fans will be able to own this alternate version and do whatever they want with it — giving them control over the destiny of the music.`,
      `"Imagination really is the tool," Rose-Garcia says. "The point is to make and create something yourself. Any way that I can allow people to apply their imagination over my music — and allow them to sculpt it using their own prompts — will let them create something new."`,
      ],
  },
  {
    id: "shanesmithandthesaint",
    name: "Shane Smith and the Saints",
    fblink: "https://www.facebook.com/shanesmithmusic/",
    instalink: "https://www.instagram.com/shanesmithmusic",
    spotifylink: "https://open.spotify.com/artist/4pLxUMyDrijXynrUP59whJ",
    spotifysource:
      "https://open.spotify.com/embed/artist/4pLxUMyDrijXynrUP59whJ?utm_source=generator",
    img: Shane,
    desc: [
       `Named after the northern winds that blow across Texas during the winter, Norther, the upcoming new album from Shane Smith & the Saints, is anything but monochromatic. Written and recorded during breaks in the band's touring schedule, the album captures Shane Smith and the Saints at their most colorful, offering up a hard-hitting version of American roots music that's influenced by country, folk, and roadhouse rock & roll. It's a sound that's been shaped by the road, where the Saints spent the past decade on tour, building a cult audience with each gig. Those years of raw, redemptive performances are now paying off — not only with headlining concerts at bucket-list venues like Red Rocks Amphitheater (which the group sold out in 36 hours) and the Ryman Auditorium, but also with an appearance on the hit TV show Yellowstone, where the Saints premiered Norther's final track, "Fire in the Ocean," with an onscreen performance.`,
      `For an independent band like Shane Smith and the Saints, the work is never done. "It's like you can't help but feel like you've paid your dues to get to a certain spot, but once you get there, you realize you're just starting to touch the surface of the bigger picture," Smith admits.`,
      ],
  },
];

export default Data;
