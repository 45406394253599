import React from "react";
import "./boxticket.css";
import ticketsBtn from "../../components/assets/DD-24-tix-lineup/Lineup/PNG/Tickets-img-btn.png";
import { Link } from "react-router-dom";
import GaTicket from "../../components/assets/DD-24-tix-lineup/images/GA.png";
import VipTicket from "../../components/assets/DD-24-tix-lineup/images/VIP.png";
import VipPlusTicket from "../../components/assets/DD-24-tix-lineup/images/VIP+.png";
import cardIcon from "../../components/assets/DD-24-tix-lineup/Tickets/SVG/cards-2x.svg";
import stateIcon from "../../components/assets/DD-24-tix-lineup/Tickets/SVG/state-2x.svg";
import DynamicDiamonds from "../../components/DynamicDiamonds/DynamicDiamonds";

// new pre-link https://www.tixr.com/groups/dreamydraw/events/dreamy-draw-country-music-festival-2024-111311

const data = [
  {
    id: 1,
    name: "G.A.",
    image: GaTicket,
    paragraph: "Sing along and connect with fellow music enthusiasts as you enjoy performances by over 25 of your favorite bands. Explore diverse food vendors, art activations, and uncover hidden gems scattered throughout the festival grounds. Bars and concessions are available throughout the festival, along with a festival merch booth to commemorate your experience.",
    bulletPoints: 
      [
        "Performances by over 20 of your favorite bands",
        "Access to all stages on festival grounds",
        "Bars and concessions available throughout the festival",
        "Delicious offerings from your favorite local food vendors",
        "Festival merch booth"
      ],
    ticketsLink:
      "https://www.tixr.com/groups/dreamydraw/events/dreamy-draw-country-music-festival-2024-111311",

  },
  {
    id: 2,
    name: "VIP",
    image: VipTicket,
    paragraph: "Elevate your festival experience with unlimited access to two VIP lounges, premium stage pit access and viewing areas, and upgraded seating in our exclusive VIP lounges. Enjoy craft cocktails and locally brewed beer at our VIP bars, and benefit from a dedicated premium and express entrance into the festival. In addition to all General Admission areas, you will have access to an exclusive festival merchandise tent.",
    bulletPoints: [
      "Unlimited access to two VIP lounges",
      "Premium Stage Pit access and viewing areas",
      "Access to VIP lounges with upgraded seating",
      "Exclusive VIP bars serving craft cocktails and locally brewed beer",
      "Dedicated premium and express entrance into the festival",
      "Exclusive festival merchandise tent",
      "One (1)  free drink per day",
      "Access to all areas available to General Admission",
      

    ],
    ticketsLink:
      "https://www.tixr.com/groups/dreamydraw/events/dreamy-draw-country-music-festival-2024-111311",
  
  },
  {
    id: 3,
    name: "VIP +",
    image: VipPlusTicket,
    paragraph: "Take it a step further with VIP PLUS, which includes all the VIP perks plus access to exclusive VIP Plus restrooms, a dedicated concierge for all your festival needs, and an exclusive merch package. Enjoy free drinks, a VIP whiskey tasting experience, and an exclusive limited print VIP+ only poster.",
    bulletPoints: [
      "Exclusive VIP + restrooms",
      "Dedicated concierge to help with any festival needs (purchasing merch, transportation, wayfinding, and more)",
      "Exclusive Merch Package",
      "5 Free Drinks Per Day",
      "Intimate Acoustic Session (Surprise Artists)",
      "Exclusive Limited Print VIP+ Only Poster",
    ],
    ticketsLink:
      "https://www.tixr.com/groups/dreamydraw/events/dreamy-draw-country-music-festival-2024-111311",
  
  },
];

const TicketBoxCard = () => {
  return (
    <div className="ticket-container">
      {data.map((item) => {
        return (
          <div className="ticket-post" key={item.id}>
            <div className="ticket-post__img">
              <img src={item.image} alt={item.name} />
            </div>
            <div className="ticket-post__info">
              <DynamicDiamonds title={item.name}/>
              <p className="ticket-post__text">{item.paragraph}</p>
              <ul className="ticket-post__text">
                {item.bulletPoints.map((point, index) => (
                  <li className="ticket-post__li" key={index}>{point}</li>
                ))}
              </ul>
              <div className="tickets-button-container">
                <div className="third-img">
                  <Link to={item.ticketsLink} target="_blank">
                    <img
                      src={ticketsBtn}
                      alt="ticket button"
                      style={{ width: "100%" }}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
      })}

        <img
          src={cardIcon}
          alt="design"
          style={{ position: "absolute", top: "18%", left: 0, width: "10%" }}
        />
        <img
          src={stateIcon}
          alt="design"
          style={{ position: "absolute", bottom: "18%", right: 0, width: "10%" }}
        />
        


    </div>
  );
};

export default TicketBoxCard;
